import React, { memo, useMemo } from "react"

import * as Components from "../../../types/components"
import * as QuizTypes from "../../../types/quiz"
import { UseTemplate } from "../../../types/hooks"
import { useQuizSubscribe } from "../../../hooks/useQuizV2"
import { useTemplate } from "../../../hooks/useTemplate"

export const withQuizSubscribePanel = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "QuizSubscribePanel" }: Components.ComponentProps) => {
    const { activeQuizSubscribePanel, data, errors, loading, success, handleSubmit, handleChange, handleToggleTab, parseSuccessContent } =
      useQuizSubscribe()
    const { global, quiz } = useTemplate()

    const locales = useMemo(
      () => ({
        ...global,
        ...quiz,
      }),
      [global, quiz]
    )

    const successContent = parseSuccessContent(locales?.additionalSubscribeSuccessContentRoute)

    const props = {
      locales,
      successContent,
      active: activeQuizSubscribePanel,
      data,
      loading,
      errors,
      success,
      handleClose: handleToggleTab,
      handleSubmit,
      handleChange,
    }

    Component.displayName = name

    return <Component {...(props as P)} />
  })

type InjectedProps = {
  active: boolean
  locales: UseTemplate.Quiz & UseTemplate.Global
  successContent: QuizTypes.QuizSubscribe.SuccessTextItem[]
  handleClose: () => void
} & Components.ComponentProps &
  Omit<QuizTypes.QuizSubscribe.Response, keyof QuizTypes.QuizSubscribe.PopupController>
