import tw, { styled } from "twin.macro"

import { StyledButton, StyledLink } from "../../../Styled/Button"
import { StyledMeatballs } from "../../../Styled/Meatball"
import { H5 } from "../../../Styled/Text"

export const Pagination = tw.nav`w-full md:w-auto mx-auto mt-12`
export const Link = styled(StyledLink)`
  ${({ center }) => (center ? tw`flex items-center mb-12 mx-auto` : "")}
`
export const LinkText = tw.span`ml-2`
export const Button = styled(StyledButton)`
  ${tw`md:w-102.5 mx-auto`}
  ${({ allowSkip }) => (allowSkip ? tw`mb-8` : tw`mb-8 md:mb-16`)}
`
export const Meatballs = tw(StyledMeatballs)`pointer-events-none`
export const Text = tw(H5)`text-orange md:px-12 md:text-2xl md:px-2 font-bold mr-2`
