import React, { memo, useMemo, useEffect, useState, useCallback } from "react"

import * as Components from "../../../types/components"
import * as QuizTypes from "../../../types/quiz"
import * as ShopifyTypes from "../../../types/shopify"
import { useImage } from "../../../hooks/useImage"
import { useShopify } from "../../../hooks/useShopify"
import { useQuizHelper, useQuizSubscribe } from "../../../hooks/useQuizV2"

export const withQuizResults = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(
    ({
      name = "QuizResults",
      answers,
      handleReset,
      handleShare,
      getResultProducts,
      getBoosterProducts,
      checkSensitivityFilter,
      locales,
      participant,
      page,
      shared,
    }: ExternalProps) => {
      const { handleOpenPopup } = useQuizSubscribe()
      const { getResponsiveImage } = useImage()
      const { useProducts } = useShopify()
      const { parseDescriptor, checkSelectedAnswer, parseResultProducts } = useQuizHelper()
      const [loading, setLoading] = useState(true)

      const isFullRoutine = useMemo(() => checkSelectedAnswer("full-routine", "looking-for", answers), [answers, checkSelectedAnswer])

      const boosterProducts = getBoosterProducts(isFullRoutine)
      const recommendedProducts = getResultProducts(isFullRoutine)

      const recommendationHandles = recommendedProducts.reduce(
        (result, item) => (item?.shopify?.shopifyHandle ? [...result, item.shopify.shopifyHandle] : result),
        []
      )

      const boosterHandles = boosterProducts.reduce(
        (result, item) => (item?.shopify?.shopifyHandle ? [...result, item.shopify.shopifyHandle] : result),
        []
      )

      const rawBoosters: ShopifyTypes.Product[] = useProducts({
        firstImages: 2,
        firstVariants: 1,
        handles: boosterHandles,
      })
      const rawRecommendations = useProducts({
        firstImages: 2,
        firstVariants: 1,
        handles: recommendationHandles,
      })

      const boosters = parseResultProducts(rawBoosters, boosterProducts)
      const recommendations = parseResultProducts(rawRecommendations, recommendedProducts)

      const banner = useMemo(
        () => ({
          background: locales?.resultsBackground,
          image: getResponsiveImage(locales?.resultsImage, { desktop: { maxWidth: 2000 }, mobile: { maxWidth: 800 } }),
          position: `center`,
          size: `medium-large`,
          title: isFullRoutine
            ? locales?.resultsTitle?.replace(`{name}`, participant)
            : locales?.resultsTitleAlternative?.replace(`{name}`, participant),
          content: isFullRoutine ? locales?.resultsSubtitle : locales?.resultsSubtitleAlternative,
          text: locales?.resultsText?.toLowerCase(),
        }),
        [
          getResponsiveImage,
          isFullRoutine,
          locales?.resultsBackground,
          locales?.resultsImage,
          locales?.resultsSubtitle,
          locales?.resultsSubtitleAlternative,
          locales?.resultsText,
          locales?.resultsTitle,
          locales?.resultsTitleAlternative,
          participant,
        ]
      )

      const loadingBanner = useMemo(
        () => ({
          background: locales?.loadingBackground,
          image: getResponsiveImage(locales?.loadingImage, { desktop: { maxWidth: 400 }, mobile: { maxWidth: 400 } }),
          position: `center`,
          size: `medium-large`,
          title: locales?.loadingTitle || "Getting your blend...",
        }),
        [getResponsiveImage, locales]
      )

      const paragraph1 = useMemo(
        () => parseDescriptor(locales?.additionalResultsParagraph1, answers),
        [answers, locales?.additionalResultsParagraph1, parseDescriptor]
      )
      const paragraph2 = useMemo(
        () => parseDescriptor(locales?.additionalResultsParagraph2, answers),
        [answers, locales?.additionalResultsParagraph2, parseDescriptor]
      )

      const paragraph3: QuizTypes.QuizHelper.ParsedDescriptor[] = useMemo(() => {
        const isSensitivity = checkSensitivityFilter()
        const paragraph3Prefix = isFullRoutine ? locales?.additionalFullRoutinePrefix : locales?.additionalSelectedProductsPrefix
        const paragraph3Suffix = isSensitivity
          ? locales?.additionalSelectedParagraphGenericSuffix
          : typeof answers?.["skin"] !== "string" && answers?.["skin"]?.[0]?.descriptionAlternative
        const merged = `${paragraph3Prefix} ${paragraph3Suffix}`
        return merged.split(" ").map(text => ({ text: `${text} ` }))
      }, [answers, isFullRoutine, locales, checkSensitivityFilter])

      const paragraphs = [paragraph1, paragraph2, paragraph3]

      const parseBoosters = (loading: boolean): QuizTypes.Quiz.MergedProduct[] => {
        return loading ? [] : isFullRoutine ? boosters : []
      }

      const parseRecommendations = (loading: boolean): QuizTypes.Quiz.MergedProduct[] => {
        if (loading) {
          return []
        }

        if (isFullRoutine) {
          return recommendations
        } else {
          // merge products if not selected "full routine"
          const merged = [...recommendations, ...boosters]

          // remove duplicates and return
          const set = new Set()
          return merged.filter(item => {
            const duplicate = set.has(item.product.id)
            set.add(item.product.id)
            return !duplicate
          })
        }
      }

      const handleSubscribePopup = useCallback(() => {
        handleOpenPopup("RESULTS15")
      }, [handleOpenPopup])

      const props = {
        banner,
        loadingBanner,
        isFullRoutine,
        loading,
        handleReset,
        handleShare,
        locales,
        participant,
        page,
        products: {
          boosters: parseBoosters(loading),
          // merge products if not selected "full routine"
          recommendations: parseRecommendations(loading),
        },
        paragraphs,
        shared,
        handleSubscribePopup,
      }

      useEffect(() => {
        if (recommendations && boosters) {
          setTimeout(() => {
            setLoading(false)
          }, 5000)
        }
      }, [recommendations, boosters])

      Component.displayName = name
      return <Component {...(props as P)} />
    }
  )

type ExternalProps = Components.ComponentProps &
  QuizTypes.Quiz.QuizResult & {
    participant: string
  }
type InjectedProps = QuizTypes.Quiz.QuizResult & {
  banner: Banner
  loadingBanner: Banner
  loading: boolean
  isFullRoutine: boolean
  participant: string
  hideContinueButton: boolean
  paragraphs: Array<QuizTypes.QuizHelper.ParsedDescriptor[]>
  products: {
    boosters: QuizTypes.Quiz.MergedProduct[]
    recommendations: QuizTypes.Quiz.MergedProduct[]
  }
  handleSubscribePopup: () => void
}

// TODO
type BannerImage = {
  desktop: any
  mobile: any
}

type Banner = {
  background: string
  image: BannerImage
  button: string
  buttonSize: string
  position: string
  size: string
  title: string
  link: { title: string }
  content: string
  text: string
}
