import React from "react"
import { graphql } from "gatsby"

import { Quiz as Page } from "../components/QuizV2/Quiz"

export const query = graphql`
  query {
    page: sanityPageQuizV2 {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
