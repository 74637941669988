import React from "react"

import { withQuizSubscribePanel } from "./withQuizSubscribePanel"
import {
  SubscribePanel,
  SubscribeSuccessWrapper,
  Form,
  FormTitle,
  Label,
  Close,
  Link,
  LinkText,
  Span,
  SuccessPanelHeading,
  SuccessPanelText,
  SuccessTextSpan,
} from "./styledQuizSubscribe"
import { Icon } from "../../Icon/Icon"
import { FormInput } from "../../Form/Input/FormInput"

export const QuizSubscribePanel = withQuizSubscribePanel(
  ({ locales, active, data, loading, success, successContent, handleClose, handleSubmit, handleChange }): JSX.Element => (
    <SubscribePanel active={active}>
      {success ? (
        <SubscribeSuccessWrapper>
          <SuccessPanelHeading align={"left"} colour={"white"} withSpacing={"sm"}>
            {locales?.additionalSubscribeSuccess}
          </SuccessPanelHeading>
          <SuccessPanelText align={"left"} colour={"white"} withSpacing={"sm"}>
            {successContent.map(content =>
              content.highlight ? <SuccessTextSpan key={content.text.toString()}>{`${content.text} `}</SuccessTextSpan> : `${content.text} `
            )}
          </SuccessPanelText>
        </SubscribeSuccessWrapper>
      ) : (
        <Form onSubmit={handleSubmit}>
          <FormTitle align={"left"} colour={"white"} withSpacing={"xxxs"}>
            {locales?.additionalSubscribeTitle}
          </FormTitle>
          <FormInput
            type={"email"}
            name={"email"}
            disabled={loading}
            full
            inverted
            onChange={handleChange}
            placeholder={locales?.additionalSubscribeEmail}
            value={data["email"]}
            withSpacing
          />
          <Label>{locales?.additionalSubscribeLabel}</Label>
          <Link type={"submit"} disabled={loading} title={locales?.additionalSubscribeSubmit}>
            <LinkText>
              {locales?.additionalSubscribeSubmit}
              <Span>
                <Icon colour={"white"} name={"right"} title={locales?.additionalSubscribeSubmit} width={15} />
              </Span>
            </LinkText>
          </Link>
        </Form>
      )}
      <Close colour={"white"} onClick={handleClose} title={locales?.additionalSubscribeButton}>
        <Icon name={"close"} />
      </Close>
    </SubscribePanel>
  )
)
