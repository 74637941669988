import React from "react"

import { withQuizSubscribe } from "./withQuizSubscribe"
import { QuizSubscribePanel } from "./QuizSubscribePanel"
import { Subscribe, SubscribeInner, SubscribeTab, Text } from "./styledQuizSubscribe"

export const QuizSubscribe = withQuizSubscribe(
  ({ locales, activePanel, isMobile, handleSubscribePopup, handleSubscribePanel }): JSX.Element => (
    <Subscribe active={activePanel}>
      <SubscribeInner>
        <QuizSubscribePanel />
        <SubscribeTab active={!activePanel} onClick={isMobile ? handleSubscribePopup : handleSubscribePanel}>
          <Text>{locales?.additionalSubscribeTag}</Text>
        </SubscribeTab>
      </SubscribeInner>
    </Subscribe>
  )
)
