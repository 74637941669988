import React from "react"

import * as QuizTypes from "../../../types/quiz"
import { withQuizSteps } from "./withQuizSteps"
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs"
import { FormInput } from "../../Form/Input/FormInput"
import { Icon } from "../../Icon/Icon"
import { HeroItem } from "../../Sections/Hero/Item/HeroItem"
import { QuizStepsPagination } from "./Pagination/QuizStepsPagination"
import { QuizSubscribe } from "../Subscribe/QuizSubscribe"
import { StyledColumn } from "../../Styled/Column"
import { StyledContainer } from "../../Styled/Container"
import { StyledRow } from "../../Styled/Row"
import { H6, P } from "../../Styled/Text"
import {
  Step,
  StepContent,
  Title,
  Subtitle,
  ContentWrapper,
  Content,
  Span,
  HyperText,
  OptionsWrapper,
  Option,
  OptionIcon,
  OptionText,
  OptionTitle,
  OptionInput,
  Space,
  StyledFormInput,
} from "./styledQuizSteps"

export const QuizSteps = withQuizSteps(
  ({
    answers,
    banner,
    currentStep,
    hideContinueButton,
    handleBack,
    handleChange,
    handleNext,
    handleOption,
    handleToggleInfoPopup,
    validateMultipleChoiceRule,
    locales,
    page,
    participant,
    step,
    steps,
    stepTypes,
  }): JSX.Element => (
    <>
      {currentStep > 0 ? (
        <>
          <QuizSubscribe locales={locales} />
          <StyledContainer width={`xl`}>
            <Step>
              <StepContent>
                {step?.icon && <Icon colour="orange" name={`face-${step.icon}`} width={100} />}
                <Title as="h3" colour="orange" withSpacing={step?.subtitle ? "xs" : "xxl"}>
                  {step?.title?.replace("{name}", participant)}
                </Title>
                {step?.subtitle && <Subtitle colour={step?.display === stepTypes.title ? "black" : "orange"}>{step?.subtitle}</Subtitle>}
                {step?.display === stepTypes.form && (
                  <>
                    <Space />
                    <FormInput
                      autoFocus
                      full
                      iconColour="orange"
                      name={step.slug}
                      onChange={handleChange}
                      placeholder={step?.placeholder}
                      required
                      value={typeof answers?.[step.slug] === "string" ? answers[step.slug] : ""}
                    />
                  </>
                )}

                {step?.display === stepTypes.custom && (
                  <CustomStepContent
                    participant={participant}
                    steps={steps}
                    answers={answers}
                    locales={locales}
                    handleToggleInfoPopup={handleToggleInfoPopup}
                  />
                )}
              </StepContent>

              {step?.display === stepTypes.options && (
                <OptionsWrapper maxWidth={step.slug !== "skin" && 280}>
                  <StyledRow gutter="xs" justify="center" withWrap>
                    {step?.options?.map((option, index) => {
                      const isActive = (answers?.[step.slug] as unknown as QuizTypes.Quiz.StepOption[])?.find(
                        selected => selected.slug === option.slug
                      )
                      let isDisabled = false
                      if (!isActive && step.allowChooseMultiple && step.requiredChoices) {
                        isDisabled = answers?.[`${step.slug}-custom`]
                          ? step.requiredChoices === answers?.[step.slug]?.length + 1
                          : step.requiredChoices === answers?.[step.slug]?.length
                      }

                      isDisabled = validateMultipleChoiceRule(isDisabled, step.slug, option.slug, answers)

                      return (
                        <StyledColumn key={option.id?.toString()} gutter={step.slug === "skin" ? "sm" : "xs"} width="no-shrink">
                          <Option
                            index={index}
                            withCustomWidth={step.slug === "skin"}
                            withIcon={!!option.icon}
                            active={isActive}
                            onClick={() => handleOption(step.slug, option, step.allowChooseMultiple)}
                            disabled={isDisabled}
                          >
                            {option.icon && (
                              <OptionIcon>
                                <Icon colour="orange" name={`face-${option.icon}`} width={100} withSpacing="none" />
                              </OptionIcon>
                            )}
                            <OptionText withIcon={!!option.icon}>
                              {option.title && option.icon ? (
                                <H6>{option.title}</H6>
                              ) : option.title ? (
                                <OptionTitle>{option.title}</OptionTitle>
                              ) : null}
                              {option.subtitle && <P>{option.subtitle}</P>}
                            </OptionText>
                          </Option>
                        </StyledColumn>
                      )
                    })}
                    {step.allowCustomInput && <CustomInputOption answers={answers} step={step} handleChange={handleChange} />}
                  </StyledRow>
                </OptionsWrapper>
              )}

              <QuizStepsPagination
                currentStep={currentStep}
                disabled={
                  (!answers?.[step.slug] ||
                    (step?.display === stepTypes.options && !answers?.[step.slug].length) ||
                    (step.requiredChoices &&
                      (answers?.[`${step.slug}-custom`]
                        ? step.requiredChoices !== answers?.[step.slug]?.length + 1
                        : step.requiredChoices !== answers?.[step.slug]?.length))) &&
                  step?.display !== stepTypes.title &&
                  step?.display !== stepTypes.custom
                }
                noButton={hideContinueButton}
                handleBack={handleBack}
                handleNext={handleNext}
                locales={locales}
                steps={steps}
                allowSkip={step?.allowSkip}
                customStep={step?.display === stepTypes.custom}
              />
            </Step>
          </StyledContainer>
        </>
      ) : (
        <HeroItem item={banner} handlePromoClick={handleNext} />
      )}
      <Breadcrumbs colour={currentStep > 0 ? `dark` : banner?.text} page={page} />
    </>
  )
)

const CustomInputOption = ({ answers, step, handleChange }: CustomInputOptionProps): JSX.Element => {
  const isActive = answers?.[`${step.slug}-custom`]
  const isDisabled = !isActive && step.allowChooseMultiple && step.requiredChoices && step.requiredChoices === answers?.[step.slug]?.length

  return (
    <StyledColumn gutter={"xs"} width="no-shrink">
      <OptionInput active={answers?.[`${step.slug}-custom`] ? "true" : undefined} disabled={isDisabled}>
        <OptionText inline>
          <H6>{"Other"}</H6>
          <StyledFormInput
            disabled={isDisabled}
            name={`${step.slug}-custom`}
            onChange={handleChange}
            value={answers?.[`${step.slug}-custom`] || ``}
          />
        </OptionText>
      </OptionInput>
    </StyledColumn>
  )
}
const CustomStepContent = ({ participant, steps, answers, locales, handleToggleInfoPopup }: CustomStepContentProps): JSX.Element => {
  const skinDescriptor = steps.find(step => step.slug === "skin")?.descriptor
  const concernsDescriptor = steps.find(step => step.slug === "concerns")?.descriptor

  const displaySensitivity = Array.isArray(answers?.sensitivity) && answers?.sensitivity?.[0].slug !== "none"

  const [skinText1, skinText] = skinDescriptor?.split("{name}") || []
  const [skinText2, skinText3] = skinText?.split("{option}") || []
  const [concernsText1, concernsText2, concernsText3] = concernsDescriptor?.split("{option}") || []
  const [sensitivityText1, sensitivityText] = locales?.additionalSummarySensitivityFilter?.split("{sensitivity}") || []
  const [sensitivityText2, sensitivityText3] = sensitivityText?.split("{popup}") || []

  // Parse answers
  const skinOption: QuizTypes.Quiz.StepOption | null = Array.isArray(answers?.skin) ? answers?.skin?.[0] : null
  const skin = skinOption ? skinOption.title : ""
  const concerns = Array.isArray(answers?.concerns) ? answers?.concerns : []
  const customConcerns = !Array.isArray(answers?.["concerns-custom"]) ? answers?.["concerns-custom"] : ""

  return (
    <>
      <ContentWrapper>
        <Content bold={true}>
          {skinText1}
          <Span>{participant}</Span>
          {skinText2}
          <Span>{skin.toLowerCase()}</Span>
          {skinText3}
        </Content>
        <Content bold={true}>
          {concernsText1}
          <Span>{concerns?.[0]?.title.toLowerCase()}</Span>
          {concernsText2}
          <Span>{concerns?.[1]?.title.toLowerCase() || customConcerns.toLowerCase()}</Span>
          {concernsText3}
        </Content>

        {displaySensitivity ? (
          <>
            <Content extraBold={true}>
              {sensitivityText1.replace("{name}", participant)}
              <Span>{"sensitivity"}</Span>
              {sensitivityText2}
              <HyperText onClick={() => handleToggleInfoPopup("sensitivity")}>{locales?.additionalSummarySensitivityFilterPopup}</HyperText>
              {sensitivityText3}
            </Content>
            <Content bold={true}>{locales?.additionalSummarySensitivityFilterDescription}</Content>
          </>
        ) : (
          <Content bold={true}>{skinOption?.description}</Content>
        )}
      </ContentWrapper>
    </>
  )
}

type CustomInputOptionProps = {
  step: QuizTypes.Quiz.Step
  answers: QuizTypes.Quiz.Answers
  handleChange: ({ target: { type, name, value, checked } }: any) => void
}

type CustomStepContentProps = {
  participant: string
  steps: QuizTypes.Quiz.Step[]
  answers: QuizTypes.Quiz.Answers
  locales: QuizTypes.Quiz.Locales
  handleToggleInfoPopup: (type: string) => void
}
