import tw from "twin.macro"
import styled from "@emotion/styled/macro"

import { StyledButton } from "../../Styled/Button"
import { H2, H5, P } from "../../Styled/Text"
import { StyledRow } from "../../Styled/Row"
import { StyledInput } from "../../Styled/Form"

export const Step = tw.section`flex flex-col items-center justify-center pt-20 pb-12 md:pt-36 md:pb-24`
export const StepContent = tw.div`flex flex-col items-center justify-center flex-1 text-center`
export const OptionInput = styled.div<OptionInputProps>`
  ${tw`flex items-center justify-center px-4 py-1 h-15 mb-4 md:mb-8 min-w-25 max-w-full animate-grow border-2 border-orange rounded-6xl`}
  ${({ active }) => (active === "true" ? tw`bg-orange bg-opacity-50` : tw`hover:bg-orange-pastel`)}
  ${({ disabled }) => (disabled ? tw`text-grey` : "")}
`
export const Option = styled(StyledButton)`
  ${tw`flex items-center justify-center mb-4 md:mb-8 min-w-25 md:max-w-full animate-grow border-2 border-orange rounded-6xl`}
  ${({ active }) => (active ? tw`bg-orange-pastel` : tw`hover:bg-orange-pastel`)}
  ${({ withIcon }) => (withIcon ? tw`px-4 py-1 md:h-30` : tw`px-4 py-1 h-15`)}
  ${({ withCustomWidth }) => (!withCustomWidth ? tw`md:max-w-300` : "")}
  ${({ disabled }) => (disabled ? tw`text-grey` : "")}
  ${({ index, withCustomWidth }) => {
    if (withCustomWidth) {
      switch (index) {
        case 0:
          return tw`w-75 md:w-75`
        case 1:
          return tw`w-75 md:w-85`
        case 2:
          return tw`w-75 md:w-92.5`
        case 3:
          return tw`w-75 md:w-102.5`
        case 4:
          return tw`w-75 md:w-117.5`
        default:
          return tw`w-auto`
      }
    } else {
      return tw`w-auto`
    }
  }}
`
export const OptionsWrapper = styled(StyledRow)`
  ${({ maxWidth }) => maxWidth === 280 && tw`md:max-w-280`}
`
export const OptionIcon = tw.div`w-25 mr-4 flex-shrink-0`
export const OptionText = styled.div<OptionTextProps>`
  ${tw`flex flex-col justify-start`}
  ${({ withIcon }) => (withIcon ? tw`items-start text-left` : tw`items-center`)}
  ${({ inline }) => (inline ? tw`flex-row` : tw`flex-col`)}
`
export const OptionTitle = tw(H5)`px-1`
export const Title = styled(H2)<TitleProps>`
  ${tw`w-full max-w-screen-sm`}
  ${({ as }) => as === `h3` && tw`max-w-300`}
  ${({ withSpacing }) => withSpacing === `xxl` && tw`mb-12 md:mb-20`}
`
export const Subtitle = styled(H5)<SubtitleProps>`
  ${tw`w-full text-orange md:px-12 md:text-2xl font-bold mb-12`}
  ${({ colour }) => (colour === `orange` ? tw`text-orange` : colour === `black` ? tw`text-black` : tw`text-orange`)}
`

export const ContentWrapper = tw.div`md:max-w-300`
export const Content = styled(P)<ContentProps>`
  ${tw`w-full max-w-screen-lg md:px-12 md:text-2xl mb-6`}
  ${({ bold, extraBold }) => (bold ? tw`font-bold` : extraBold ? tw`font-black` : tw`font-medium`)}
`
export const Span = tw.span`text-purple-dark`
export const HyperText = tw.span`underline cursor-pointer`

export const Space = tw.div`mb-12 md:mb-20`

export const StyledFormInput = tw(StyledInput)`ml-4 -mt-5.75`

interface SubtitleProps {
  colour: "orange" | "black"
}
interface ContentProps {
  bold?: boolean
  extraBold?: boolean
}
interface TitleProps {
  withSpacing?: string
}
interface OptionTextProps {
  withIcon?: boolean
  inline?: boolean
}
interface OptionInputProps {
  active?: "true" | undefined
  disabled?: boolean
}
