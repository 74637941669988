import React from "react"

import { withQuizStepsPagination } from "./withQuizStepsPagination"
import { Icon } from "../../../Icon/Icon"
import { StyledMeatball } from "../../../Styled/Meatball"
import { StyledColumn } from "../../../Styled/Column"
import { StyledRow } from "../../../Styled/Row"
import { Pagination, Button, Link, LinkText, Meatballs, Text } from "./styledQuizStepsPagination"

export const QuizStepsPagination = withQuizStepsPagination(
  ({ disabled, handleBack, handleNext, items, locales, noButton, allowSkip, customStep }): JSX.Element => (
    <Pagination>
      {!noButton && (
        <Button
          colour={`purple`}
          allowSkip={allowSkip}
          disabled={disabled}
          full={`true`}
          size={`primary`}
          onClick={handleNext}
          title={locales?.additionalNext}
        >
          {customStep ? locales?.additionalNextAlternative : locales?.additionalNext}
        </Button>
      )}
      {allowSkip && (
        <Link onClick={handleNext} title={"Skip"} center>
          <Text>{"Skip "}</Text>
          <Icon name={`right`} colour={"orange"} title={"Skip"} width={12} />
        </Link>
      )}
      <StyledRow gutter={`sm`} items={`center`} justify={`center`}>
        <StyledColumn gutter={`sm`} width={`no-shrink`}>
          <Link onClick={handleBack} title={locales?.additionalPrevious}>
            <Icon name={`left`} title={locales?.additionalPrevious} width={12} /> <LinkText>{locales?.additionalPrevious}</LinkText>
          </Link>
        </StyledColumn>
        <StyledColumn gutter={`sm`} width={`no-shrink`}>
          <Meatballs align={`left`}>
            {items?.map(({ active, id }) => (
              <StyledMeatball key={id?.toString()} active={active} colour={`orange`} />
            ))}
          </Meatballs>
        </StyledColumn>
      </StyledRow>
    </Pagination>
  )
)
