import React from "react"

import { withQuiz } from "./withQuiz"
import { QuizResults } from "./Results/QuizResults"
import { QuizSteps } from "./Steps/QuizSteps"

export const Quiz = withQuiz(
  ({
    answers,
    complete,
    currentStep,
    handleBack,
    handleChange,
    handleNext,
    handleOption,
    handleReset,
    handleShare,
    handleToggleInfoPopup,
    validateMultipleChoiceRule,
    checkSensitivityFilter,
    getResultProducts,
    getBoosterProducts,
    locales,
    participant,
    page,
    shared,
    step,
    steps,
    stepTypes,
  }): JSX.Element => (
    <>
      {step && !complete ? (
        <QuizSteps
          answers={answers}
          currentStep={currentStep}
          handleBack={handleBack}
          handleChange={handleChange}
          handleNext={handleNext}
          handleOption={handleOption}
          handleToggleInfoPopup={handleToggleInfoPopup}
          validateMultipleChoiceRule={validateMultipleChoiceRule}
          locales={locales}
          participant={participant}
          page={page}
          step={step}
          steps={steps}
          stepTypes={stepTypes}
        />
      ) : (
        <QuizResults
          answers={answers}
          handleReset={handleReset}
          handleShare={handleShare}
          getResultProducts={getResultProducts}
          getBoosterProducts={getBoosterProducts}
          checkSensitivityFilter={checkSensitivityFilter}
          locales={locales}
          participant={participant}
          page={page}
          shared={shared}
        />
      )}
    </>
  )
)
