import React, { memo, useCallback } from "react"

import * as Components from "../../../types/components"
import * as QuizTypes from "../../../types/quiz"
import { useDom } from "../../../hooks/useDom"
import { useQuizSubscribe } from "../../../hooks/useQuizV2"

export const withQuizSubscribe = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "QuizSubscribe", locales }: ExternalProps) => {
    const { dom } = useDom()
    const { activeQuizSubscribePanel, handleToggleTab, handleOpenPopup } = useQuizSubscribe()

    const handleSubscribePanel = useCallback(() => {
      handleToggleTab()
    }, [handleToggleTab])

    const handleSubscribePopup = useCallback(() => {
      handleOpenPopup("ROUTINE15")
    }, [handleOpenPopup])

    const props = {
      locales,
      activePanel: activeQuizSubscribePanel,
      isMobile: !dom.isMedium,
      handleSubscribePopup,
      handleSubscribePanel,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  locales: QuizTypes.Quiz.Locales
}

type InjectedProps = {
  locales: QuizTypes.Quiz.Locales
  activePanel: boolean
  isMobile: boolean
  handleSubscribePopup: () => void
  handleSubscribePanel: () => void
}
