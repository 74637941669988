import React, { memo } from "react"

import * as Components from "../../types/components"
import * as QuizTypes from "../../types/quiz"
import { useQuiz } from "../../hooks/useQuizV2"

export const withQuiz = <P extends QuizTypes.Quiz.Response>(Component: React.ComponentType<P>) =>
  memo(({ name = "Quiz", location, page }: ExternalProps) => {
    const {
      answers,
      complete,
      currentStep,
      handleBack,
      handleChange,
      handleNext,
      handleOption,
      handleReset,
      handleShare,
      handleToggleInfoPopup,
      validateMultipleChoiceRule,
      checkSensitivityFilter,
      getResultProducts,
      getBoosterProducts,
      locales,
      participant,
      shared,
      step,
      steps,
      stepTypes,
    } = useQuiz(location)

    const props = {
      answers,
      complete,
      currentStep,
      handleBack,
      handleChange,
      handleNext,
      handleOption,
      handleReset,
      handleShare,
      handleToggleInfoPopup,
      validateMultipleChoiceRule,
      checkSensitivityFilter,
      getResultProducts,
      getBoosterProducts,
      locales,
      participant,
      page,
      shared,
      step,
      steps,
      stepTypes,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })
interface ExternalProps extends Components.ComponentProps {
  page?: Components.PageProps
}
