import React, { memo, useMemo } from "react"

import * as Components from "../../../types/components"
import * as QuizTypes from "../../../types/quiz"
import { useImage } from "../../../hooks/useImage"

export const withQuizSteps = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(
    ({
      name = "QuizSteps",
      answers,
      currentStep,
      handleBack,
      handleChange,
      handleNext,
      handleOption,
      handleToggleInfoPopup,
      validateMultipleChoiceRule,
      locales,
      page,
      step,
      steps,
      stepTypes,
    }: ExternalProps) => {
      const { getResponsiveImage } = useImage()

      const banner = useMemo(
        () => ({
          background: locales?.bannerBackground,
          image: getResponsiveImage(locales?.bannerImage, { desktop: { maxWidth: 2000 }, mobile: { maxWidth: 800 } }),
          button: "purple",
          buttonSize: "secondary",
          position: "middle",
          size: "medium-large",
          title: locales?.bannerTitle,
          link: { title: locales?.additionalStart },
          content: locales?.bannerSubtitle,
          text: locales?.bannerText?.toLowerCase(),
          dense: true,
        }),
        [getResponsiveImage, locales]
      )

      const participant = useMemo(
        () => (Array.isArray(answers?.participant) ? answers?.participant?.[0].title : answers?.participant),
        [answers?.participant]
      )

      const hideContinueButton = step.display === stepTypes.options && !step.showContinueButton && !step.allowCustomInput && !step.allowChooseMultiple

      const props = {
        answers,
        banner,
        currentStep,
        hideContinueButton,
        handleBack,
        handleChange,
        handleNext,
        handleOption,
        handleToggleInfoPopup,
        validateMultipleChoiceRule,
        locales,
        page,
        participant,
        step,
        steps,
        stepTypes,
      }

      Component.displayName = name
      return <Component {...(props as P)} />
    }
  )

type ExternalProps = Components.ComponentProps &
  QuizTypes.Quiz.QuizSteps & {
    participant: string
  }
type InjectedProps = QuizTypes.Quiz.QuizSteps & {
  banner: Banner
  participant: string
  hideContinueButton: boolean
}

// TODO
type BannerImage = {
  desktop: any
  mobile: any
}

type Banner = {
  background: string
  image: BannerImage
  button: string
  buttonSize: string
  position: string
  size: string
  title: string
  link: { title: string }
  content: string
  text: string
  dense: boolean
}
