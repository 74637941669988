import React, { memo, useMemo } from "react"

export const withQuizStepsPagination = Component =>
  memo(
    ({
      name = "QuizStepsPagination",
      currentStep,
      disabled = false,
      handleBack,
      handleNext,
      locales,
      noButton = false,
      allowSkip = false,
      steps,
      customStep = false,
    }: any) => {
      const items = [...(steps?.map(({ id }) => ({ id })) || [])]?.map(({ id }, index: number) => ({ active: currentStep > index, id }))

      Component.displayName = name
      return useMemo(
        () => (
          <Component
            disabled={disabled}
            handleBack={handleBack}
            handleNext={handleNext}
            items={items}
            locales={locales}
            noButton={noButton}
            allowSkip={allowSkip}
            customStep={customStep}
          />
        ),
        [customStep, disabled, handleBack, handleNext, items, locales, noButton, allowSkip]
      )
    }
  )
