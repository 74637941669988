import tw from "twin.macro"
import styled from "@emotion/styled/macro"

import { GlobalStyles, GlobalStylesTypes } from "../../Styled/Global"
import { StyledButton, StyledLink } from "../../Styled/Button"
import { StyledColumn } from "../../Styled/Column"
import { StyledContainer } from "../../Styled/Container"
import { StyledRow } from "../../Styled/Row"
import { H5, P } from "../../Styled/Text"

export const Navigation = tw.nav`py-2 md:py-3 border-b border-orange md:text-center mb-12 md:mb-16`
export const NavigationBuffer = tw.div`h-16`
export const Links = tw.ul`flex items-center justify-center md:-mx-6`
export const LinkItem = styled.li<GenericProps>`
  ${tw`block md:px-6`}
  ${({ screen }) => screen && GlobalStyles?.screen[screen]}
`
export const LinkButton = tw(StyledLink)`block font-medium uppercase whitespace-nowrap`
export const Products = tw(StyledRow)`mb-2 md:mb-12`
export const Boosters = tw.div`-mt-4 mb-2 md:mb-12 md:mt-0`
export const Step = styled(P)`
  ${tw`text-sm md:text-2xl font-normal`}

  strong {
    ${tw`block md:inline`}
  }
`
export const Column = styled(StyledColumn)<ColumnProps>`
  ${tw`flex flex-col md:flex-row py-1.875 md:py-3.75`}
  ${({ withBorder }) => withBorder && tw`md:border-r md:border-purple-dark`}
  ${({ colour }) => GlobalStyles.background[colour]}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
`
export const Container = styled(StyledContainer)`
  ${tw`py-12 md:py-16`}
  ${({ image }) => (image ? tw`max-w-117.5` : "")}
`
export const Button = tw(StyledButton)`md:w-102.5`

export const ContentWrapper = tw(StyledContainer)`mb-12 md:mb-24 md:text-center`
export const Content = styled(P)<ContentProps>`
  ${tw`w-full max-w-screen-lg md:px-12 md:text-2xl mb-6`}
  ${({ bold, extraBold }) => (bold ? tw`font-bold` : extraBold ? tw`font-black` : tw`font-medium`)}
  ${({ italic }) => (italic ? tw`italic` : "")}
`
export const Span = tw.span`text-purple-dark`

export const StyledColumnInner = tw.div`mt-6 md:mt-0`
export const ProductContentWrapper = tw.div``
export const ProductContent = styled.div<ProductContentProps>`
  ${tw`w-full`}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
`
export const ProductContentLabel = styled(H5)<ProductContentLabelProps>`
  ${tw``}
  ${({ large }) => (large ? tw`text-2xl md:text-2xl` : tw`text-xl md:text-xl`)}
  ${({ colour }) => GlobalStyles.colour[colour]}
`
export const ProductContentDivider = styled.div<ProductContentDividerProps>`
  ${tw`w-full border-t`}
  ${({ border }) => GlobalStyles.border[border]}
`

interface ContentProps {
  bold?: boolean
  extraBold?: boolean
  italic?: boolean
}
interface ProductContentProps {
  withSpacing?: GlobalStylesTypes["spacing"]
}
interface ProductContentLabelProps {
  colour?: GlobalStylesTypes["background"]
  large?: boolean
}
interface ProductContentDividerProps {
  border?: GlobalStylesTypes["border"]
}
interface ColumnProps {
  withBorder?: boolean
  colour?: GlobalStylesTypes["background"]
  withSpacing?: GlobalStylesTypes["spacing"]
}
interface GenericProps {
  screen?: GlobalStylesTypes["screen"]
}
