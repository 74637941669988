import React from "react"

import { withQuizResults } from "./withQuizResults"
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs"
import { HeroItem } from "../../Sections/Hero/Item/HeroItem"
import { ProductCard } from "../../Product/Card/ProductCard"
import { StyledColumn } from "../../Styled/Column"
import { StyledContainer } from "../../Styled/Container"
import { StyledRow } from "../../Styled/Row"
import { H3, H4, H5, P, RichText } from "../../Styled/Text"
import { StyledImage } from "../../Styled/Image"
import {
  Navigation,
  NavigationBuffer,
  Links,
  LinkItem,
  LinkButton,
  Step,
  Column,
  Products,
  Boosters,
  Container,
  Button,
  Content,
  ContentWrapper,
  Span,
  StyledColumnInner,
  ProductContentWrapper,
  ProductContent,
  ProductContentLabel,
  ProductContentDivider,
} from "./styledQuizResults"

export const QuizResults = withQuizResults(
  ({
    banner,
    loadingBanner,
    loading,
    isFullRoutine,
    handleReset,
    handleShare,
    locales,
    page,
    paragraphs,
    shared,
    products,
    handleSubscribePopup,
  }): JSX.Element =>
    loading ? (
      <Container width={`sm`} image>
        <StyledImage alt={loadingBanner.image.desktop.alt} fluid={{ ...loadingBanner.image.desktop, aspectRatio: 3 / 4 }} />
        <H5 align={`center`} colour={`grey-darker`} withSpacing={`sm`}>
          {loadingBanner.title}
        </H5>
      </Container>
    ) : (
      <>
        <HeroItem item={banner} size={`medium`} />
        <Breadcrumbs colour={banner?.text} page={page} />
        {!shared ? (
          <Navigation>
            <StyledContainer width={`sm`}>
              <Links>
                <LinkItem>
                  <LinkButton colour={`purple-dark`} onClick={handleReset} title={locales?.additionalRestart} underline={`true`}>
                    {locales?.additionalRestart}
                  </LinkButton>
                </LinkItem>
              </Links>
            </StyledContainer>
          </Navigation>
        ) : (
          <NavigationBuffer />
        )}

        <ContentWrapper condensed={`md>`} width={`lg`}>
          {paragraphs.map((paragraph, index) => (
            <Content key={index} bold={true}>
              {paragraph?.map((item, index) =>
                item.highlight ? <Span key={`${index}_${item.text}`}>{item.text}</Span> : <span key={`${index}_${item.text}`}>{item.text}</span>
              )}
            </Content>
          ))}
          <Content italic={true}>{locales?.additionalPromoLabel}</Content>
          <StyledRow justify={`center`}>
            <Button colour={`purple`} onClick={handleSubscribePopup} size={`primary`} title={locales?.additionalMoreInfo}>
              {locales?.additionalMoreInfo}
            </Button>
          </StyledRow>
        </ContentWrapper>

        <StyledContainer width={`xl`}>
          {products?.recommendations?.length > 0 && (
            <Products gutter={`md`} items={`stretch`} withWrap>
              {products?.recommendations?.length > 0 && (
                <StyledColumn gutter={`default`} width={`default`}>
                  <StyledRow gutter={`sm`} items={`stretch`} withWrap>
                    {products?.recommendations?.map(({ _id, product, displayContent }) => (
                      <Column
                        key={_id?.toString()}
                        colour={`${product.colour}-pastel`}
                        gutter={`none`}
                        items={`start`}
                        justify={`end`}
                        width={`default`}
                        withSpacing={`md`}
                      >
                        <StyledColumn gutter={`sm`} items={`start`} justify={`end`} vertical width={`md:1/3`}>
                          <ProductCard external product={product} hideTitle />
                        </StyledColumn>
                        <StyledColumn gutter={`sm`} items={`start`} justify={`end`} vertical width={`md:2/3`}>
                          <StyledColumnInner>
                            {displayContent.map((item, index) => {
                              switch (item.type) {
                                case "heading":
                                case "content":
                                  return (
                                    <ProductContentWrapper key={index}>
                                      <ProductContent withSpacing={item.type === "heading" ? "lg" : "sm"}>
                                        <ProductContentLabel uppercase large={item.type === "heading"} colour={product.colour}>
                                          {item.label}
                                        </ProductContentLabel>
                                      </ProductContent>
                                      {item.content && (
                                        <ProductContent withSpacing={`sm`}>
                                          {typeof item.content === "string" ? (
                                            <P withSpacing={`none`}>{item.content}</P>
                                          ) : Array.isArray(item.content) ? (
                                            item.content.map((text, index) => (
                                              <P key={index} withSpacing={`none`}>
                                                {text}
                                              </P>
                                            ))
                                          ) : (
                                            <RichText withSpacing={`none`}>{item.content}</RichText>
                                          )}
                                        </ProductContent>
                                      )}
                                    </ProductContentWrapper>
                                  )
                                case "title":
                                  return (
                                    <ProductContent withSpacing={`md`} key={index}>
                                      <H5 uppercase>{product.title}</H5>
                                      {product.type ? (
                                        <P withSpacing={`none`}>{product.type}</P>
                                      ) : (
                                        <P dangerouslySetInnerHTML={{ __html: `&nbsp;` }} withSpacing={`none`} />
                                      )}
                                    </ProductContent>
                                  )
                                case "divider":
                                  return (
                                    <ProductContent withSpacing={`sm`} key={index}>
                                      <ProductContentDivider border={product.colour} />
                                    </ProductContent>
                                  )
                              }
                            })}
                          </StyledColumnInner>
                        </StyledColumn>
                      </Column>
                    ))}
                  </StyledRow>
                </StyledColumn>
              )}
            </Products>
          )}
        </StyledContainer>

        {isFullRoutine && (
          <ContentWrapper condensed={`md>`} width={`lg`}>
            <Content bold={true}>{locales?.additionalDailyDietLabel}</Content>
            <StyledRow justify={`center`}>
              <Button as={"a"} href={"/daily-diet"} target="_blank" colour={`purple`} size={`primary`} title={locales?.additionalLearnMore}>
                {locales?.additionalLearnMore}
              </Button>
            </StyledRow>
          </ContentWrapper>
        )}

        {products?.boosters?.length > 0 && (
          <StyledContainer width={`xl`}>
            <Boosters>
              <H3 colour={`purple-dark`} uppercase withSpacing={`sm`}>
                {locales?.additionalBoosters}
              </H3>
              <StyledRow gutter={`sm`} justify={`start`} items={`stretch`} withWrap>
                {products?.boosters?.map(({ _id, product, content, displayContent }) => (
                  <StyledColumn key={_id?.toString()} gutter={`sm`} items={`start`} justify={`end`} vertical width={`1/2 md:1/4`}>
                    <Step colour={`purple-dark`} uppercase withSpacing={`xs`}>
                      {displayContent?.find(item => item.type === "heading")?.label ||
                        content?.displayHeading ||
                        product?.productType ||
                        product?.type}
                    </Step>
                    <ProductCard external product={product} withSpacing={`lg`} />
                  </StyledColumn>
                ))}
              </StyledRow>
            </Boosters>
          </StyledContainer>
        )}

        {!shared && (
          <StyledContainer width={`sm`} withSpacing={`xl`}>
            <H4 align={`center`} withSpacing={`sm`}>
              {isFullRoutine ? locales?.additionalShareTitle : locales?.additionalHelpLabel}
            </H4>
            <StyledRow justify={`center`}>
              <Button colour={`purple`} onClick={handleShare} size={`primary`} title={locales?.additionalShare}>
                {locales?.additionalShare}
              </Button>
            </StyledRow>
          </StyledContainer>
        )}
      </>
    )
)
